.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}
.heroLineOne {
  color: #049a5b;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-variant: small-caps;
  margin-bottom: 14px;
}
.textWrapper {
  max-width: 636px;
}
.heroLineTwo {
  color: #fff;
  /* heading */
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 142%;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.heroLineThree {
  color: rgba(238, 238, 238, 0.8);
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;
  max-width: 580px;
}

.heroImage {
  width: 100%;
  height: 100%;
  max-width: 569px;
}

@media screen and (max-width: 991px) {
  .heroImage {
    width: 100%;
    height: 100%;
    max-width: 569px;
  }
  .wrapper {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 75px;
    height: 100vh;
  }
  .heroImage {
    max-width: 569px;
  }
  .heroLineOne,
  .heroLineTwo,
  .heroLineThree {
    text-align: center;
  }
  .heroLineOne {
    font-size: 14px;
  }
  .heroLineTwo {
    font-size: 24px;
  }
  .heroLineThree {
    font-size: 16px;
  }
  .textWrapper,
  .imageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
