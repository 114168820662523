.loaderBox {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loaderBox img {
  max-height: 450px;
}
