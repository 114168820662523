.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.heroContent {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.subtitle {
  color: #049a5b;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-variant: small-caps;
  margin-bottom: 21px;
}
.title {
  color: #eee;
  text-align: center;
  max-width: 733px;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 142%; /* 51.12px */
  text-transform: capitalize;
  margin-bottom: 36px;
}
.content {
  color: rgba(238, 238, 238, 0.8);
  text-align: center;

  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  margin-bottom: 44px;
  max-width: 634px;
}
.heroAction {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 50px;
}
.play {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.videoButton {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: 0.3s;
}
.videoButton:hover {
  color: #00a86b;
}
.videoButton:hover .iconWrapper {
  transform: scale(1.1);
}
.iconWrapper {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s;
  font-size: 18px;
  color: #fff;
}

.iconWrapper:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 991px) {
  .subtitle {
    font-size: 14px;
    margin-bottom: 21px;
  }
  .title {
    font-size: 24px;
    margin-bottom: 36px;
  }
  .content {
    font-size: 16px;
    margin-bottom: 44px;
  }
}
