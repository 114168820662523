header {
  position: relative;
}

.links,
.navbar {
  display: flex;
  gap: 35px;
  align-items: center;
  height: 80px;
}

.navbar,
.navbarMobile {
  position: relative;
  top: 0;
  width: 100%;
  background-color: #000000c7;

  justify-content: space-between;
}

.navbar a,
.mobileLinks a {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  transition: color ease 0.2s;
  white-space: nowrap;
}

.navbar a:hover,
.mobileLinks a:hover {
  color: var(--green-color);
}

.separator {
  background: #adb3bf;
  width: 1px;
  height: 46px;
  opacity: 0.2;
}

.authButtons {
  display: flex;
  align-items: center;
  gap: 50px;
}

.navWrapperOpen,
.navWrapperClosed {
  top: 0;
  left: 0;
  position: fixed;
  height: 100dvh;
  width: 100vw;
  background-color: #000;

  transition: all 0.3s;
  z-index: 100;
}

.navWrapperClosed {
  height: 0;
}

.mobileNavHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}
.mobileLinksWrapper {
  position: relative;
}
.mobileLinks {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 80dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.product {
  position: relative;
}

.product h6 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
}

.product:hover h6 {
  color: var(--green-color);
}

.product h6 .dropDownIcon {
  rotate: 0deg;
  transition: 0.3s;
}

.product:hover h6 .dropDownIcon {
  rotate: 180deg;
}

.product:hover .productLinks {
  opacity: 1;
  max-height: 500px;
  display: grid;
}

.productLinks {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  border-top: 15px solid #000;
  animation: showDropDown 1s ease;
  opacity: 0;
  display: none;
  max-height: 0px;
  position: absolute;
  width: 400px;
  padding: 25px;
  border-radius: 3px;
  background: #181818;
  z-index: 10;
  transition: 1s;
}

.productLink {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.productLink:hover a {
  color: var(--green-color);
}

.productLink a {
  flex-shrink: 0;
}

.product:hover .productNoLinksWrapper {
  display: block;
}
.productNoLinksWrapper {
  display: none;
}
.productLinksWrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  transition: 0.3s;
}
/* Mbile drop down */

.productMobileDisActive,
.productMobileActive {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
}

.productMobileActive {
  color: var(--green-color);
  transition: 0.3s;
}

.productMobileDisActive .dropDownIcon {
  rotate: 0deg;
  transition: 0.3s;
}

.productMobileActive .dropDownIcon {
  rotate: 180deg;
  transition: 0.3s;
}

.product:hover .productLinks {
  opacity: 1;
  max-height: 500px;
  display: grid;
}

/* End mobile drop down */
.productLinksMobile {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 30px;
}
.productLinksMobile p {
  font-size: 14px;
  font-weight: 400;
}
.loginButton:nth-child(2) {
  border-radius: 3px;
  border: 1px solid #02341f;
  background: #000601;
  backdrop-filter: blur(30.5px);
  padding: 14px 32px;
  transition: 0.3s;
}
.loginButton:nth-child(2):hover {
  background: #ffffff;
}
/* ---------- Hamburger Styles ---------- */
.hamburger,
.hamburgerActive {
  position: relative;
  z-index: 10;
  cursor: pointer;
  display: block;
}
.hamburger span,
.hamburgerActive span {
  display: block;
  width: 30px;
  height: 3px;
  background-color: #fff;
  margin: 5px 0;
  transition: all 0.3s ease;
}
.hamburgerActive span:nth-child(1) {
  transform: rotate(45deg) translate(6px, 6px);
}
.hamburgerActive span:nth-child(2) {
  opacity: 0;
}
.hamburgerActive span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* ---------- Hamburger Styles ---------- */

@media screen and (max-width: 991px) {
  .productLinks {
    left: -150px;
  }
  .mobileLinks {
    /* height: 70vh; */
    animation: showDropDown 2s;
  }
}

@media screen and (max-width: 576px) {
  .productLinks {
    padding: 15px;
    width: max-content;
    left: 0;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
  }
}

@keyframes showDropDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
