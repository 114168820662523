.wrapper {
  width: 100%;
  height: 85px;
  background: linear-gradient(
    136deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 36.06%,
    rgba(92, 92, 92, 0.06) 95.31%
  );
  backdrop-filter: blur(16.5px);
}
.topFooter {
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textTitle {
  display: flex;
  align-items: center;
  gap: 19px;
}
.textTitle img {
  width: 48px;
  height: 51px;
}
.text {
  color: #fff;
  font-size: 20px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media screen and (max-width: 991px) {
  .wrapper {
    width: 100%;
    height: 100%;
    padding: 30px;
  }
  .text {
    text-align: center;
  }
  .topFooter {
    height: 100%;
    flex-direction: column;
    gap: 30px;
  }
  .textTitle {
    flex-direction: column;
  }
}
