.wrapper {
  margin-bottom: 180px;
}
.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 18px;
}
.miniTitle {
  color: #ddd;
  text-align: center;
  font-size: 16px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.28px;
  text-transform: uppercase;
}
.title {
  color: #fff;
  font-size: 36px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: 142%;
  text-transform: capitalize;
}
.tabsTitlesRows {
  margin-top: 142px;
  display: flex;
  flex-direction: column;
  gap: 58px;
}
.tableWrapper,
.tableWrapperActive {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 60px;
  row-gap: 50px;
  align-items: start;
  justify-content: center;
  padding-bottom: 21px;
}
.tableWrapperActive {
  border-bottom: #111 1px solid;
  align-items: center;
}
.rightSide {
  display: flex;
  flex-direction: column;
  gap: 60px;
  position: relative;
}
.descSide {
  align-items: center;
  display: flex;
  /* gap: 31px; */
  justify-content: space-between;
  margin-left: 70px;
}

.activeTitle {
  color: #fff;
  font-size: 48px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  position: relative;
  cursor: pointer;
  margin-left: 50px;
  white-space: nowrap;
  transition: 0.3s;
  animation: fadeInOut 2s ease;
}

.activeTitle::after {
  position: absolute;
  bottom: 0;
  margin-left: 5px;
  content: '';
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background-color: #21ac73;
  animation: fadeInOut 2s ease;
}

.normalTitle {
  color: #6b6b6b;
  font-size: 32px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}
.tabsDesc {
  max-width: 566px;
  width: 100%;
  color: #eee;
  font-size: 18px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.imageWrapper {
  position: absolute;
  top: 100px;
  left: -35px;
  animation: fadeInOut 2.5s ease;
}

.imageWrapperTop {
  position: absolute;
  top: -430px;
  left: -35px;
  animation: fadeInOut 2.5s ease;
}

.tradeLink {
  color: #049a5b;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 9px;
  transition: 0.4s;
  white-space: nowrap;
  margin-right: 50px;
}
.tradeLink:hover {
  /* gap: 18px; */
  color: #2bdb92;
}
.tradeLink svg {
  font-size: 18px;
}

/* Mobile Component */
.mobileWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  max-width: 500px;
  max-height: 400px;
  width: 100%;
  height: 100%;
}
.mobileStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  border: #a1a1a138 solid 1px;
  padding: 20px;
  border-radius: 20px;
}
.mobileTitle {
  color: #fff;
  font-size: 24px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: 142%;
  text-transform: capitalize;

  white-space: nowrap;
  transition: 0.3s;
  text-align: center;
}

.imageMobileWrapper,
.imageMobileWrapper img {
  max-width: 400px;
  width: 100%;
}
.mobileListCard {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
}
@media screen and (max-width: 1250px) {
  .activeTitle {
    color: #fff;
    font-size: 34px;
  }
  .tabsDesc {
    max-width: 566px;
    width: 100%;
    color: #eee;
    font-size: 14px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .tradeLink {
    font-size: 14px;
    margin-right: 0px;
  }
  .activeTitle::after {
    width: 10px;
    height: 10px;
  }
}
@media screen and (max-width: 1024px) {
  .wrapper {
    margin-bottom: 100px;
  }
  .miniTitle {
    color: #ddd;
    text-align: center;
    font-size: 14px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.28px;
    text-transform: uppercase;
  }
  .mobileListCard {
    margin-top: 10px;
  }
  .title {
    color: #fff;
    font-size: 26px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    line-height: 142%;
    text-transform: capitalize;
  }
}
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
