.wrapperE {
  margin-top: 223px;
  margin-bottom: 221px;
  margin-inline: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.enterEmailWrapper {
  background-position: -624px -494.617px / 205.071% 416.739%;
  background-repeat: no-repeat;
  width: 1262px;
  height: 324px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 69px;
  border-radius: 25px;
  background-color: #23a77231;
  backdrop-filter: blur(5px);
}

.title {
  color: #fff;
  font-size: 48px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  font-variant: small-caps;
  line-height: normal;
  max-width: 551px;
  margin-top: -20px;
}
.emailWrapper {
  display: flex;
  flex-direction: column;
  gap: 41px;
  z-index: 1;
}

.description {
  color: #eee;
  font-size: 20px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  line-height: 135.9%;
  max-width: 445px;
}
.inputWrapper {
  border-radius: 4px;
  border: 1px solid rgba(130, 130, 130, 0.33);
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(57.5px);
  padding: 11px 13px;
}
.inputEmail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.eniterYourEmail {
  font-size: 20px;
}
.eniterYourEmail ::placeholder {
  color: #b8b8b8;
  font-size: 16px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  line-height: 135.9%;
}

@media screen and (max-width: 1300px) {
  .enterEmailWrapper {
    background-position: right;
    height: fit-content;
    width: fit-content;
    background-size: cover;
  }
}
@media screen and (max-width: 1200px) {
  .enterEmailWrapper {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .emailWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 30px;
  }
}
@media screen and (max-width: 991px) {
  .wrapperE {
    margin-top: 100px;
    margin-bottom: 100px;
    margin-inline: 0px;
  }
  .enterEmailWrapper {
    padding: 39px;
    border-radius: 25px;
  }
}
@media screen and (max-width: 768px) {
  .title {
    font-size: 38px;
  }
  .description {
    font-size: 16px;
  }
  .inputWrapper {
    padding: 6px 8px;
  }
  .buttonMobile {
    font-size: 12px;
    padding: 10px;
  }
  .eniterYourEmail {
    font-size: 14px;
  }
  .eniterYourEmail ::placeholder {
    font-size: 12px;
  }
}

@media screen and (max-width: 425px) {
  .title {
    font-size: 28px;
    margin-top: 0;
  }
  .description {
    font-size: 16px;
  }
  .eniterYourEmail {
    border: 1px solid #ccc;
  }
  .inputWrapper {
    border-radius: 0;
    border: none;
    background: transparent;
    backdrop-filter: blur(0);
    padding: 0px 0px;
  }
  .inputEmail {
    justify-content: center;
    flex-direction: column;
  }
  .enterEmailWrapper {
    padding: 20px;
  }
}
