.sectionOne {
  margin-bottom: 10rem;
  position: relative;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sectionOneTitle {
  color: #fff;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 24px;
}
.experience {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
}
.sectionOneSecondTitle {
  color: #fff;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
  max-width: 436px;
}
.sectionOneBody {
  color: rgba(238, 238, 238, 0.8);
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 574px;
}
.sectionOneImage {
  width: 100%;
  height: 100%;
  max-width: 484px;
}

.firstWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sectionOneSecondImage {
  width: 100%;
  height: 100%;
  max-width: 414px;
}

.smallTitle {
  color: #049a5b;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
  margin-bottom: 19px;
}
.phoneWrapper {
  margin-top: 150px;
}

/* Phone Wrapper */
.catering {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 100px;
}
.ImageWrapper,
.imageWrapperExp {
  display: flex;
  align-items: center;
  justify-content: end;
}
.phoneImage {
  width: 100%;
  max-width: 484px;
}
.title {
  color: #fff;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.content {
  color: #eee;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%;
  max-width: 491.629px;
}

.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cardFullWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  row-gap: 59px;
  column-gap: 82px;
}
@media screen and (max-width: 1300px) {
  .cardFullWrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 991px) {
  .sectionOneSecondTitle {
    font-size: 28px;
  }
  .cardFullWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .experience {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr;
  }
  .catering {
    grid-template-columns: 1fr;
    gap: 50px;
  }
  .ImageWrapper,
  .imageWrapperExp {
    justify-content: center;
  }
  .textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .title {
    color: #fff;
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }

  .content {
    color: #eee;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%;
    max-width: 491.629px;
  }
}

@media screen and (max-width: 1200px) {
  .infoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
