.wrapper {
  position: relative;
  overflow: hidden;
}

.wrapper::before {
  display: inline;
  content: '';
  position: absolute;
  width: 40vw;
  height: 40vh;
  left: 0%;
  transform: rotate(24.629deg);
  flex-shrink: 0;
  border-radius: 392px;
  opacity: 0.95;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(18, 255, 170, 0.6) 31.77%,
    rgba(9, 9, 9, 0.08) 100%
  );
  filter: blur(191px);
  z-index: -100;
}

.wrapper::after {
  display: inline;
  content: '';
  position: absolute;
  right: 0%;
  top: 50%;
  width: 40vw;
  height: 40vh;
  transform: rotate(24.629deg);
  flex-shrink: 0;
  border-radius: 392px;
  opacity: 0.95;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(18, 255, 170, 0.6) 31.77%,
    rgba(9, 9, 9, 0.08) 100%
  );
  filter: blur(191px);
  z-index: -100;
}
.notFoundView {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  height: 95vh;
  /* width: 95vw; */
  margin: 30px;
  padding: 30px;
}
.notImage {
  width: 100%;
  max-width: 500px;
  text-align: center;
}
.title {
  color: #fff;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 142%;
  text-transform: capitalize;
  text-align: center;
}
.content {
  color: rgba(238, 238, 238, 0.8);
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
