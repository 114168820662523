.wrapper {
  position: relative;
}
.wrapper::before {
  position: absolute;
  content: url('../../../../Assets/images/ZenTokenView/zenTokenZenInfoSection.png');
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.empowering {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 120px;
}
.empoweringHeading {
  color: #fff;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ListItem {
  padding: 32px;
  transition: background-color 0.5s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
}
.ListItem:hover {
  background-color: #026139;
  transform: translateX(50px);
  background-image: url('../../../../Assets/images/ZenTokenView/zenTokenHeroRightSectionOpacity.png');
  animation: moveBackgroundImage 5s infinite linear;
}

.itemTitle {
  color: #fff;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.itemBody {
  margin-top: 17px;
  color: #eee;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  max-width: 652px;
}
@media screen and (max-width: 991px) {
  .ListItem:hover {
    transform: translateX(0px);
  }
  .empowering {
    gap: 20px;
  }
  .wrapper::before {
    content: '';
  }
  .empoweringHeading {
    font-size: 26px;
  }
  .itemTitle {
    font-size: 18px;
  }
  .itemBody {
    margin-top: 17px;
    font-size: 14px;
  }
  .ListItem {
    max-width: 400px;
  }
  .cardWrapper,
  .empowering,
  .ListItem,
  .empoweringHeading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

@media screen and (max-width: 425px) {
  .ListItem:hover {
    background-color: unset;
    transform: unset;
    background-image: unset;
  }
}

@keyframes moveBackgroundImage {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}
