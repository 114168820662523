.wrapper {
  position: relative;
}

.wrapper::after {
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 448px;
  height: 448px;
  border-radius: 448px;
  opacity: 0.30000001192092896;
  background: #17ce81;
  filter: blur(250px);
}
.heroSetion {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 150px;
}
.topCoin {
  position: relative;
}
.topCoin::before {
  content: url('../../../../Assets/images/twoCoinE.png');
  position: absolute;
  top: 0;
  left: 0px;
  animation: waterEffect 2s ease-in-out infinite;
}
.topCoin::after {
  content: url('../../../../Assets/images/fourCoinB.png');
  position: absolute;
  top: 0;
  right: 0px;
  animation: waterEffect 4s ease-in-out infinite;
}
.bottomCoin {
  position: relative;
}
.bottomCoin::before {
  content: url('../../../../Assets/images/oneCoinT.png');
  position: absolute;
  bottom: 0;
  left: 10%;
  animation: waterEffect 3s ease-in-out infinite;
}
.bottomCoin::after {
  content: url('../../../../Assets/images/threeCoinE.png');
  position: absolute;
  bottom: 0;
  right: 5%;
  animation: waterEffectTwo 2s ease-in-out infinite;
}
.title {
  color: #fff;
  text-align: center;
  font-size: 48px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: capitalize;
  max-width: 874px;
}
.desc {
  color: #eee;
  text-align: center;
  font-size: 20px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  max-width: 808px;
}
.heroSetion img {
  margin-top: 36px;
}

/* Media Query */
@media screen and (max-width: 1090px) {
  .topCoin {
    display: none;
  }
  .topCoin::before {
    content: '';
  }
  .topCoin::after {
    content: '';
  }
  .bottomCoin {
    display: none;
  }
  .bottomCoin::before {
    content: '';
  }
  .bottomCoin::after {
    content: '';
  }
  .heroSetion {
    margin-top: 90px;
  }
  .title {
    font-size: 38px;
  }
  .desc {
    font-size: 16px;
  }

  .heroSetion img {
    animation: waterEffect 2s ease-in-out infinite;
  }
}
@media screen and (max-width: 450px) {
  .wrapper::after {
    width: 100%;
    height: 100%;
    border-radius: 448px;
  }
}

@media screen and (max-width: 425px) {
  .title {
    font-size: 28px;
  }
  .desc {
    font-size: 14px;
  }
}
/* keyframes */
@keyframes waterEffect {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes waterEffectTwo {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes movingBackgroundOne {
  0% {
    bottom: 0;
    right: 0%;
    opacity: 0.25;
  }
  50% {
    bottom: 100%;
    right: 100%;
    opacity: 0.25;
  }
  100% {
    bottom: 0;
    right: 0%;
    opacity: 0.25;
  }
}

@keyframes movingBackgroundTwo {
  0% {
    top: 0;
    right: 0%;
    opacity: 0.25;
  }
  50% {
    top: 100%;
    right: 100%;
    opacity: 0.25;
  }
  100% {
    top: 0;
    right: 0%;
    opacity: 0.25;
  }
}
