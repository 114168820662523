/* Time Line */
.wrapper {
  margin-top: 140px;
}
.timeLine {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dateActive {
  color: #049a5b;
  text-align: center;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.date {
  color: #494949;
  text-align: center;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 50px;
}
.partLeftWrapper,
.partLeftWrapperDark {
  position: relative;
}
.partLeftWrapperDark {
  margin-top: 100px;
}
.partLeftWrapperDarkDate {
  margin-top: 0px;
}
.partLeft {
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  justify-content: center;
  align-items: center;
  gap: 57px;
  margin-top: 50px;
}

.imagesLineWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.imagesLine {
  position: absolute;
  bottom: 100%;
}
.imagesLine {
}
.title,
.titleE {
  color: #eee;
  font-family: Raleway;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.title {
  text-align: end;
}
.titleE {
  text-align: start;
}
/* Card component */
.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardTimeLine {
  padding: 28px 38px;
  display: grid;
  grid-template-columns: 1fr;
  border: #eeeeee2a solid 1px;
  gap: 20px;
  width: 100%;
  max-width: 540px;
  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(34, 35, 37, 0) 100%,
    rgba(214, 214, 214, 0) 100%
  );

  backdrop-filter: blur(6px);
  transition: 0.36s;
}
.singleList {
  max-width: 240px;
  white-space: wrap;
}
.cardTimeLine:hover {
  transform: translateY(-10px);
}
.first ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}
.first ul li {
  list-style-type: disc;
  color: #eee;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.singleListFirst {
  white-space: nowrap;
}
.imagesLineMobileWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.titleWrapper {
  margin-block: 30px;
}
@media screen and (max-width: 1200px) {
  .first ul li {
    white-space: wrap;
  }
}
@media screen and (max-width: 1024px) {
  .partLeft {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    gap: 57px;
    margin-top: 50px;
  }
  .title,
  .titleE {
    text-align: center;
  }
}
@media screen and (max-width: 560px) {
  .cardTimeLine {
    margin-block: 20px;
    width: 100%;
  }
  .first ul {
    display: grid;
    grid-template-columns: 1fr;
  }
  .first ul li {
    font-size: 12px;
    white-space: wrap;
  }
}
