.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
}
.ourValuesCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
}
.ourValuesCards::after {
  content: url('../../../../Assets/images/ourValueBG.svg');
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.ourValuesCards::before {
  content: '';
  position: absolute;
  width: 90px;
  height: 90px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 68px;
  opacity: 0.30000001192092896;
  background: radial-gradient(
    81.14% 81.14% at 50% 50%,
    #17ce81 68.54%,
    rgba(161, 255, 215, 0) 100%
  );
  filter: blur(25px);
  z-index: 1;
}
.cardsTop,
.cardsBottom {
  flex-basis: 50%;
  display: flex;
  justify-content: space-around;
  column-gap: 68px;
}

.logoWrapper {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  height: 41px;
  width: 41px;
  margin: 17px;
}

.mainTitle {
  margin-bottom: 86px;
  color: #fff;
  font-size: 40px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
}
.title {
  color: #fff;
  font-size: 22px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 22.25px;
}
.desc {
  color: #ddd;
  font-size: 18px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  max-width: 379px;
}
.valuesCard {
  border: 1px #eeeeee33 solid;
  width: 549px;
  height: 220px;
  border-radius: 6px;
  background: linear-gradient(
    136deg,
    rgba(33, 33, 33, 0.05) 0%,
    rgba(92, 92, 92, 0.03) 100%
  );
  backdrop-filter: blur(16.5px);
  padding: 47px 50px;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  transition: 0.3s;
}
.valuesCard:hover {
  transform: translateY(-10px);
}
@media screen and (max-width: 1260px) {
  .cardsTop,
  .cardsBottom {
    column-gap: 38px;
  }
  .title {
    font-size: 18px;
  }
  .desc {
    font-size: 14px;
  }
  .valuesCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px #eeeeee33 solid;
    width: 400px;
    height: 220px;
    padding: 27px 30px;
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 1042px) {
  .wrapper {
    margin-bottom: 50px;
  }
  .mainTitle {
    margin-bottom: 43px;
  }
  .ourValuesCards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .ourValuesCards::after {
    content: '';
  }
  .ourValuesCards::before {
    content: '';
    position: absolute;
    display: none;
  }
  .cardsTop,
  .cardsBottom {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .logoWrapper {
    order: -1;
  }
  .logoWrapper img {
    width: 41px;
  }
  .valuesCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px #eeeeee33 solid;
    width: 100%;
    height: 100%;
    max-width: 549px;
    max-height: 220px;
    padding: 27px 30px;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 425px) {
  .valuesCard:hover {
    transform: unset;
  }
}
