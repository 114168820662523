.wrapper {
  position: relative;
  margin-top: -70px;
  margin-bottom: 50px;
}
.wrapper::after {
  width: 448px;
  height: 448px;
  position: absolute;
  content: '';
  border-radius: 448px;
  opacity: 0.30000001192092896;
  background: radial-gradient(
    81.14% 81.14% at 50% 50%,
    #17ce81 68.54%,
    rgba(161, 255, 215, 0) 100%
  );
  filter: blur(250px);
  top: -200px;
  right: 0;
  z-index: -1;
}
.communityWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
}
.textWrapper {
  display: flex;
  flex-direction: column;
  max-width: 580px;
}
.title {
  color: #fff;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 142%;
  text-transform: capitalize;
  margin-bottom: 3px;
}
.content {
  color: rgba(238, 238, 238, 0.8);
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  margin-bottom: 37px;
}

.imageWrapper img {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .wrapper::after {
    width: 248px;
    height: 248px;
    position: absolute;
    content: '';
    border-radius: 448px;
    opacity: 0.30000001192092896;
    background: radial-gradient(
      81.14% 81.14% at 50% 50%,
      #17ce81 68.54%,
      rgba(161, 255, 215, 0) 100%
    );
    filter: blur(250px);
    top: -200px;
    right: 0;
    z-index: -1;
  }
  .communityWrapper {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 40px;
  }
  .textWrapper {
    align-items: center;
    justify-content: center;
  }
  .imageWrapper img {
    max-width: 430px;
  }
  .title {
    font-size: 24px;
    text-align: center;

    margin-bottom: 3px;
  }
  .content {
    font-size: 18px;

    text-align: center;
  }
}
