
.separatorHorizontal{
    margin:0 auto;
    display:block;
    width:100%;
    height:1px;
}

.separatorVertical {
    margin:0 auto;
    display:block;
    height:100%;
    width:1px;
}