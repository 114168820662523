.wrapper {
  margin-block: 187px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.SingleCrypto {
  border: 1px solid #616161;
  width: fit-content;
  display: flex;
  padding: 18px 27px;
  align-items: center;
  justify-content: center;
  /* width: fit-content; */
  gap: 12px;
  border-radius: 66px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(34, 35, 37, 0) 100%,
    rgba(214, 214, 214, 0) 100%
  );
  backdrop-filter: blur(6px);
  transition: 0.3s;
}
.wrapperSingle {
  margin-right: 20px;
}
.cryptoWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 22px;
}
.singleCryptoWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  text-align: center;
  font-size: 20px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  white-space: nowrap;
}
.image {
  width: 36px;
  height: 36px;
}

@media screen and (max-width: 991px) {
  .wrapper {
    margin-block: 100px;
  }
  .SingleCrypto {
    flex-direction: row;
    padding: 11px 20px;
  }

  .title {
    font-size: 16px;
  }
  .image {
    width: 26px;
    height: 26px;
  }
}

/* Scroll */
.scroll {
  position: relative;
  width: 100vw;
  overflow: hidden;
  z-index: 1;
  margin: 0;
  padding: 0;
}

.mScroll {
  overflow: hidden;
  height: 100%;
  white-space: nowrap;
  animation: scrollText 70s infinite linear;
  margin: 0;
  font-size: 0;
  display: flex;
  justify-content: space-between;
  width: 300%;
}
.lScroll {
  animation: scrollTextTwo 70s infinite linear;
  overflow: hidden;
  height: 100%;
  white-space: nowrap;
  margin: 0;
  font-size: 0;
  display: flex;
  justify-content: space-between;
  width: 300%;
}
span {
  display: inline-block;
  margin: 0;
  padding: 0;
  color: white;
}

@media screen and (min-width: 2000px) {
  .mScroll,
  .lScroll {
    width: 200%;
  }
}

@keyframes scrollText {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes scrollTextTwo {
  0% {
    transform: translateX(-50%);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
