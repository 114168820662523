.wrapper {
  margin-block: 180px;
}
.reliabilitySection {
  position: relative;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}
.reliabilitySection::before {
  display: inline;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 300px;
  border-radius: 700px;
  opacity: 0.30000001192092896;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(18, 255, 170, 0.6) 31.77%,
    rgba(9, 9, 9, 0.08) 100%
  );
  filter: blur(140px);
  z-index: -1;
}
.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 18px;
}
.miniTitle {
  color: #ddd;
  text-align: center;
  font-size: 16px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.28px;
  text-transform: uppercase;
}
.title {
  color: #fff;
  font-size: 36px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: 142%;
  text-transform: capitalize;
}
.contentWrapper {
  margin-top: 122px;
}
.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}
.reliabilityContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleCard,
.titleCardTwo,
.titleCardThree {
  color: #fff;
  font-size: 18px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 17px;
  white-space: nowrap;
}
.titleCard {
  transform: rotate(5.653deg);
  margin-left: 8px;
}
.titleCardTwo {
  transform: rotate(-3.835deg);
  margin-left: -5px;
}
.titleCardThree {
  transform: rotate(-4.926deg);
  margin-left: -5px;
}
.descCard,
.descCardTwo,
.descCardThree {
  color: #eee;
  font-size: 16px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.descCard {
  transform: rotate(5.653deg);
}
.descCardTwo {
  transform: rotate(-3.835deg);
}
.descCardThree {
  transform: rotate(-4.926deg);
}
.cardOneWrapper,
.cardTwoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 190px;
}

.cardOne {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 305px;
  height: 250px;
  transform: rotate(5.653deg);
  background-image: url('../../../../Assets//images/card1.svg');
  background-size: cover;
  background-repeat: no-repeat;
  padding-block: 50px;
  padding-inline: 28px 40px;
}
.cardTwo {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  height: 275px;
  transform: rotate(-3.835deg);
  background-image: url('../../../../Assets//images/card2.svg');
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 40px;
  padding-inline: 28px 40px;
}
.cardThree {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  height: 270px;
  transform: rotate(-4.926deg);
  background-image: url('../../../../Assets/images/card3.svg');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 52px 28px;
  margin-bottom: 10px;
}
.phoneImage {
  background-image: url('../../../../Assets/images/iPhone13.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 392px;
  height: 660px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
}
@media screen and (max-width: 1100px) {
  .contentWrapper {
    margin-top: 62px;
  }
  .wrapper {
    margin-block: 100px;
  }
  .title {
    font-size: 32px;
  }
  .content {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: space-evenly;
  }
  .cardOneWrapper,
  .cardTwoWrapper {
    gap: 0px;
  }
  .cardOneWrapper {
    flex-direction: row;
  }
  .miniTitle {
    font-size: 14px;
  }
  .cardOne {
    width: 350px;
    height: 190px;
    transform: rotate(0);
    background-image: url('../../../../Assets//images/cardMob1.svg');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 20px;
    padding-right: 40px;
  }
  .cardTwo {
    width: 350px;
    height: 190px;
    transform: rotate(0);
    background-image: url('../../../../Assets//images/cardMob2.svg');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 20px;
    padding-right: 40px;
  }
  .cardThree {
    width: 350px;
    height: 190px;
    transform: rotate(0);
    background-image: url('../../../../Assets//images/cardMob3.svg');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 20px;
    padding-right: 40px;
  }
  .descCard {
    transform: rotate(0);
  }
  .descCardTwo {
    transform: rotate(0);
  }
  .descCardThree {
    transform: rotate(0);
  }
  .titleCard {
    transform: rotate(0);
    margin-left: 0;
  }
  .titleCardTwo {
    transform: rotate(0);
    margin-left: 0;
  }
  .titleCardThree {
    transform: rotate(0);
    margin-left: 0;
  }
  .reliabilityContent {
    order: -1;
  }
}
@media screen and (max-width: 800px) {
  .cardOneWrapper {
    flex-direction: column;
  }
}
@media screen and (max-width: 425px) {
  .title {
    color: #fff;
    font-size: 26px;
    line-height: 142%;
  }
  .phoneImage {
    width: 70%;
    height: 50vh;
  }
  .descCard,
  .descCardTwo,
  .descCardThree {
    font-size: 14px;
  }

  .titleCard,
  .titleCardTwo,
  .titleCardThree {
    font-size: 16px;
  }
  .cardOne,
  .cardTwo,
  .cardThree {
    width: 300px;
    height: 155px;
  }
}

@media screen and (max-width: 425px) {
  .cardOne:hover,
  .cardTwo:hover,
  .cardThree:hover {
    animation: unset;
  }
  .phoneImage {
    width: 70%;
    height: 30vh;
  }
  .descCard,
  .descCardTwo,
  .descCardThree {
    font-size: 12px;
  }

  .titleCard,
  .titleCardTwo,
  .titleCardThree {
    font-size: 14px;
  }
  .cardOne,
  .cardTwo,
  .cardThree {
    width: 260px;
    height: 140px;
  }
}

.cardOne:hover,
.cardTwo:hover,
.cardThree:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.7s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
