.content {
  overflow: hidden;
}
.contentImage {
  width: 100%;
  height: 100%;
  max-width: 606px;
}
.contentSection {
  margin-bottom: 10rem;
  position: relative;
}

/* --------------- Section Two ----------- */
.potential {
  text-align: center;
  margin-bottom: 90px;
}
.card {
  padding-inline: 33px;
  padding-block: 45px;
  border-radius: 10px;
}
.cardDesc {
  max-width: 400px;
  color: #eee;
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  line-height: 148%;
}
.rewards {
  background: url('../../../../Assets/images/ZenTokenView/zenTokenContentRewards.png'),
    linear-gradient(177deg, #101010 40%, rgba(16, 16, 16, 0) 110%);
  background-repeat: no-repeat;
  background-position: bottom right;
}
.lock {
  background: url('../../../../Assets/images/ZenTokenView/zenTokenContentLock.png'),
    linear-gradient(177deg, #101010 40%, rgba(16, 16, 16, 0) 110%);
  background-repeat: no-repeat;
  background-position: bottom right;
}
.chart {
  background: url('../../../../Assets/images/ZenTokenView/zenTokenContentChart.png'),
    linear-gradient(177deg, #101010 40%, rgba(16, 16, 16, 0) 110%);
  background-repeat: no-repeat;
  background-position: bottom right;
}
/* --------------- Section Two ----------- */

/* --------------- Steps ----------- */
.cardImage {
  margin-bottom: 25px;
}
.cardTitle {
  color: #fff;
  /* Ttile */
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.smallDesc {
  color: #ddd;
  text-align: center;
  /* body */
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  max-width: 323px;
}
.smallCard {
  text-align: center;
  max-width: 323px;
}

/* --------------- Steps ----------- */

/* --------------- Monthly Rewards ----------- */

.limitList {
  max-height: 45px;
  transition: all 0.5s ease;
  overflow: hidden;

  padding-inline: 10px;
  margin-bottom: 20px;
}
.limitList:hover .title {
  color: #049a5b;
}
.mainTitleM {
  color: #fff;
  /* heading */
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 142%; /* 51.12px */
  text-transform: capitalize;
  margin-bottom: 60px;
}
.title {
  font-family: Raleway;
  font-style: normal;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 20px;
}
.title:hover {
  cursor: pointer;
}
.active .title {
  color: #049a5b;
}
.active {
  max-height: 350px;
  border-color: var(--primary);
  margin-bottom: 25px;
}
.arrow {
  display: flex;
  transition: all 0.5s ease;
}
.active .arrow {
  transform: rotate(180deg);
}
.colWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
}

.smallDescM,
.smallDescM a {
  max-width: 596px;
  color: #ddd;
  /* body */
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}
.smallDescM a {
  border-bottom: #fff solid 1px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.smallDescM a:hover {
  color: #049a5b;
  border-bottom: #049a5b solid 1px;
}
/* --------------- Monthly Rewards ----------- */

/* --------------- SignUp ----------- */

.cardTitle {
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 25px;
}
.signUpDesc {
  color: #ddd;
  /* body */
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  margin-bottom: 55px;
  max-width: 500px;
}
.signUpTitle {
  color: #fff;
  /* Ttile */
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 25px;
}
.colWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* --------------- SignUp ----------- */
@media screen and (max-width: 1200px) {
  .imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
@media screen and (max-width: 991px) {
  .mainTitleM {
    color: #fff;
    /* heading */
    font-family: Raleway;
    font-size: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 142%; /* 51.12px */
    text-transform: capitalize;
    margin-bottom: 40px;
  }
  .potential {
    text-align: center;
    margin-bottom: 40px;
  }
  .active {
    max-height: 400px;
  }
  .buttonWrapper {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .colWrapper {
    flex-direction: column-reverse;
    gap: 30px;
    text-align: center;
    justify-content: center;
  }
  .sideWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    text-align: center;
  }
  .signUpDesc {
    font-size: 14px;
    text-align: center;
  }
  .signUpTitle {
    font-size: 18px;
    text-align: center;
  }
}

/* section 2 */
.wrapperUnluck {
  margin-bottom: 200px;
}
.unluck {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cardWrapper {
  margin-top: 87px;
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.mainTitle {
  color: #fff;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.cardTitle,
.cardRightTitle {
  color: #fff;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  z-index: 100;
}
.cardContent,
.cardRightContent {
  margin-top: 12px;
  color: #eee;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%;
}
.cardContent {
  max-width: 271px;
}
.cardRightContent {
  max-width: 408px;
}
.cardOne {
  position: relative;
  width: 352px;
  height: 447px;
  padding: 33px;
  border-radius: 10px;
  background: linear-gradient(177deg, #101010 0%, rgba(16, 16, 16, 0) 100%);
  transition: 0.5s;
}
.cardOne:hover {
  background-color: #23a7724d;
}
.cardOne::after {
  content: url('../../../../Assets/images/ZenTokenView/zenTokenContentRewards.png');
  position: absolute;
  bottom: 0;
  right: 0;
}
.cardRigh {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.cardTwo,
.cardThree {
  width: 670px;
  height: 213px;
  padding: 33px;
  border-radius: 10px;
  background: linear-gradient(177deg, #101010 0%, rgba(16, 16, 16, 0) 100%);
  position: relative;
  transition: 0.5s;
}
.cardTwo:hover,
.cardThree:hover {
  background-color: #23a7724d;
}
.cardTwo::after {
  content: url('../../../../Assets/images/ZenTokenView/zenTokenContentLock.png');
  position: absolute;
  bottom: 0;
  right: 0;
}
.cardThree::after {
  content: url('../../../../Assets/images/ZenTokenView/zenTokenContentChart.png');
  position: absolute;
  bottom: 0;
  right: 0;
}
/* section 4 */
.start {
  padding-block: 56px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.signinLink {
  margin-top: 22px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.signinLinkText,
.signinLinkIcon {
  color: #049a5b;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.titleCrypto {
  color: #fff;
  font-family: Raleway;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.leftWrapper p {
  color: rgba(238, 238, 238, 0.8);
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 574px;
}
@media screen and (max-width: 1050px) {
  .start {
    padding-block: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .rightWrapper,
  .leftWrapper p {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .cardOne {
    width: 670px;
    height: 213px;
  }
  .cardContent {
    max-width: 408px;
  }
  .cardWrapper {
    margin-top: 27px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .wrapperUnluck,
  .wrapperExperience {
    margin-bottom: 60px;
  }
  .expreience {
    grid-template-columns: 1fr;
    gap: 20px;
    text-align: center;
  }

  .title {
    font-size: 22px;
  }
  .desc {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .titleCrypto {
    font-size: 22px;
  }
  .signinLinkText,
  .signinLinkIcon {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .leftWrapper p {
    font-size: 16px;
  }
  .cardOne::after,
  .cardTwo::after,
  .cardThree::after {
    content: '';
  }
  .mainTitle {
    font-size: 26px;
    text-align: center;
  }
  .cardTitle,
  .cardRightTitle {
    font-size: 18px;
  }
  .cardContent,
  .cardRightContent {
    font-size: 14px;
  }
  .cardOne,
  .cardTwo,
  .cardThree {
    max-width: 670px;
    max-height: 213px;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 33px;
    border-radius: 10px;
    background: linear-gradient(177deg, #101010 0%, rgba(16, 16, 16, 0) 100%);
    position: relative;
  }
}
