.firstRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 40px;
}
.firstLeft,
.firstRight,
.thirdLeft,
.thirdRight {
  position: relative;
}
.firstLeft:hover,
.firstRight:hover,
.thirdLeft:hover,
.thirdRight:hover {
  animation: shake 0.7s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}
.firstLeftClipped {
  clip-path: polygon(0 0, 100% 4%, 100% 95%, 0 100%);
  padding: 45px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(92, 92, 92, 0.2)
  );
  transform: rotate(-4.809deg);
}
.firstRightClipped {
  clip-path: polygon(0 4%, 100% 0, 100% 100%, 0 95%);
  padding: 45px;
  transform: rotate(5.705deg);
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(92, 92, 92, 0.2)
  );
}
.OneBody {
  color: #eee;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
  max-width: 323.742px;
}
.oneTitle {
  color: #fff;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 152.9%; /* 30.58px */
  text-transform: capitalize;
  margin-bottom: 20px;
}
.secondRow {
  display: flex;
  justify-content: center;
  margin-block: 6rem;
}
.secondRow {
  display: flex;
  justify-content: center;
}
.secondRowContent h2,
.secondRowContent h6 {
  text-align: center;
  background: linear-gradient(95deg, #049a5b 0%, #2af8ae 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
}
.secondRowContent h2 {
  font-size: 40px;
}
.secondRowContent h6 {
  font-size: 22px;
}
.thirdRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 40px;
}

.thirdLeftClipped {
  clip-path: polygon(0 0, 100% 4%, 100% 95%, 0 100%);
  padding: 45px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(92, 92, 92, 0.2)
  );
  transform: rotate(0.603deg);
}
.thirdRightClipped {
  clip-path: polygon(0 4%, 100% 0, 100% 100%, 0 95%);
  padding: 45px;
  transform: rotate(-4.419deg);
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(92, 92, 92, 0.2)
  );
}

.leftTopIcon {
  position: absolute;
  top: -40px;
  right: -15px;
}
.rightTopIcon {
  position: absolute;
  top: -34px;
  right: -50px;
}
.leftBottomIcon {
  position: absolute;
  top: -40px;
  right: -35px;
}
.rightBottomIcon {
  position: absolute;
  top: -65px;
  right: -35px;
}

@media screen and (max-width: 1200px) {
  .secondRow {
    order: -1;
  }
  .firstLeftClipped,
  .firstRightClipped,
  .thirdRightClipped,
  .thirdLeftClipped {
    transform: rotate(0);
    clip-path: none;
  }
  .firstRow,
  .thirdRow {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .firstLeftClipped,
  .firstRightClipped,
  .thirdRightClipped,
  .thirdLeftClipped {
    text-align: center;
  }
  .secondRowContent h2 {
    font-size: 28px;
  }
  .secondRowContent h6 {
    font-size: 18px;
  }
  .OneBody {
    font-size: 14px;
  }
  .oneTitle {
    font-size: 18px;
  }
  .leftTopIcon {
    display: none;
  }
  .rightTopIcon {
    display: none;
  }
  .leftBottomIcon {
    display: none;
  }
  .rightBottomIcon {
    display: none;
  }
}
@media screen and (max-width: 425px) {
  .firstLeft:hover,
  .firstRight:hover,
  .thirdLeft:hover,
  .thirdRight:hover {
    animation: unset;
  }
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
