.missionAndVision {
  display: flex;
  width: 1170px;
  height: 384px;
  position: relative;
}
.wrapper {
  margin-block: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.missionAndVision::after {
  z-index: -111111;
  display: inline;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 992px;
  max-height: 371px;
  transform: rotate(24.629deg);
  flex-shrink: 0;
  border-radius: 992px;
  opacity: 0.25;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(18, 255, 170, 0.6) 31.77%,
    rgba(9, 9, 9, 0.08) 100%
  );
  filter: blur(191px);
  z-index: -100;

  /* Animation */
  animation: movingBackground 15s linear infinite;

  /* Ignore pointer events */
  pointer-events: none;
}
.vision {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 11px;
  border-radius: 100px;
  background-color: #070707;
  max-width: 651px;
  flex-direction: column;
  transition: all 0.9s ease;
  padding: 71px;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.mission {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 11px;
  border-radius: 100px 100px 100px 0px;
  background-color: #23a773;
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 651px;
  flex-direction: column;
  padding: 71px;
  transition: all 0.9s ease;
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: 0;
}

.vision:hover {
  background-color: #23a773;

  background-repeat: no-repeat;
  background-size: cover;
}
.vision:hover + .mission {
  background-image: unset;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #070707;
}

.title {
  color: #fff;
  font-size: 32px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.desc {
  color: #eee;
  font-size: 20px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  max-width: 477px;
}

@media screen and (max-width: 1200px) {
  .missionAndVision {
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .vision,
  .mission {
    position: relative;
  }
}

@media screen and (max-width: 670px) {
  .vision {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 11px;
    border-radius: 50px;
    background-image: unset;
    padding: 31px;
    transition: 0.9s;
  }
  .mission {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 11px;
    border-radius: 50px 50px 50px 0px;
    background-color: #23a773;
    background-image: unset;
    padding: 31px;
    transition: 0.9s;
  }
  .vision:hover {
    background-image: unset;
    background-color: #23a773;
  }
  .title {
    font-size: 22px;
  }
  .desc {
    font-size: 14px;
  }
}

@media screen and (max-width: 425px) {
  .vision:hover {
    background-color: unset;

    background-repeat: unset;
    background-size: unset;
  }
  .vision:hover + .mission {
    background-image: unset;
    background-repeat: unset;
    background-size: unset;
    background-color: unset;
  }
  .missionAndVision::after {
    display: none;
  }
}
@keyframes movingBackground {
  0% {
    top: 0;
    right: 0;
    transform: rotate(0deg);
    opacity: 0;
    border-radius: 50%;
    background-color: rgba(86, 255, 193, 0.6);
  }
  25% {
    top: 50%;
    right: 50%;
    transform: rotate(90deg);
    opacity: 0.25;
    border-radius: 0;
    background-color: #00a86b;
  }
  50% {
    top: 100%;
    right: 100%;
    transform: rotate(180deg);
    opacity: 0.5;
    border-radius: 50%;
    background-color: #005e3b;
  }
  75% {
    top: 50%;
    right: 50%;
    transform: rotate(270deg);
    opacity: 0.25;
    border-radius: 0;
    background-color: #00311f;
  }
  100% {
    top: 0;
    right: 0;
    transform: rotate(360deg);
    opacity: 0;
    border-radius: 50%;
    background-color: rgba(18, 255, 170, 0.6);
  }
}
