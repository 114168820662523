@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
}

html {
  font-size: 16px;
  background: #000000;
}

:root {
  --white-color: #fff;
  --green-color: #049a5b;
  --grey-color: #dddddd;
}

/* SCROLLBAR Customizations */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background-clip: content-box;
  background-color: rgba(0, 0, 0, 0);
  width: 20px;
}

::-webkit-scrollbar-thumb {
  border: 1px solid transparent;
  background-color: var(--orange-color);
  border-radius: 2px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

body {
  scroll-behavior: smooth;
  font-family: 'Raleway', sans-serif;
  line-height: 1.6;
  color: var(--white-color);
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  font-size: 1rem;
}
h1 {
  font-size: 3rem; /* 48px */
}
h2 {
  font-size: 2.25rem; /* 36px */
}
h3 {
  font-size: 1.375rem; /* 22px */
}
h4 {
  font-size: 1.25rem; /* 20px */
}
h5 {
  font-size: 1.125rem; /* 18px */
}
h6 {
  font-size: 1.1rem; /* 16px */
}
p {
  font-size: 0.875rem; /* 14px */
}
ul {
  list-style: none;
  padding: 0;
}


@media screen and (max-width: 567px) {
  html {
    font-size: 12px;
  }
}

.swiper {
  margin-top: 2rem;
}
.swiper-wrapper {
  position: relative;
  transition-timing-function: linear !important;
  -webkit-transition-timing-function: linear !important;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}
.swiper {
  margin-top: 2rem;
}
.swiper-wrapper {
  position: relative;
  transition-timing-function: linear !important;
  -webkit-transition-timing-function: linear !important;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}
