.wrapper {
  margin-block: 150px 200px;
}
/* Main */
.whyChooseZenit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mainTitle {
  text-align: center;
  color: #eee;
  font-size: 32px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: 155%;
  margin-bottom: 107px;
}
.cardWrapper {
  display: flex;
  justify-content: space-between;
  align-self: center;
  gap: 60px;
}
/* Card Component */
.cardWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 26px;
}
.cardImage {
  width: 100px;
  height: 100px;
}
.cardTitle {
  color: #fff;
  font-size: 22px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.cardDesc {
  color: #eee;
  text-align: center;
  font-size: 18px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  max-width: 400px;
}
@media screen and (max-width: 991px) {
  .wrapper {
    margin-block: 100px;
  }
  .cardWrapper {
    flex-direction: column;
  }
  .mainTitle {
    font-size: 22px;
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 375px) {
  .cardImage {
    width: 70px;
    height: 70px;
  }
  .cardTitle {
    font-size: 18px;
  }
  .cardDesc {
    font-size: 14px;
  }
}
