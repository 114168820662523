.howItWorks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.mainTitle {
  color: #fff;
  font-size: 36px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.subTitle {
  margin-top: 20px;
  color: rgba(238, 238, 238, 0.8);
  font-size: 18px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.number {
  color: #049a5b;
  font-size: 24px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.title {
  color: #fff;
  font-size: 22px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  white-space: nowrap;
}
.componentWrapper {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0px;
}
.listWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  row-gap: 80px;
  column-gap: 15px;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 27px;
  position: relative;
  padding: 20px;
}

.list::before {
  opacity: 0.5;
  content: '';
  position: absolute;
  bottom: -27px; /* Adjust the value based on the gap between components */
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(89, 88, 88, 0.32) 0%,
    #9f9d9d 46.35%,
    rgba(97, 97, 97, 0) 100%
  );
}
.list:nth-child(2),
.list:nth-child(4) {
  padding-left: 30px;
}
.list:nth-child(4)::before,
.list:nth-child(5)::before {
  display: none;
}
.list:nth-child(1)::after,
.list:nth-child(3)::after {
  opacity: 0.5;

  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: calc(
    100% + 54px
  ); /* Adjust the value based on the component height and gap */
  background: linear-gradient(
    0deg,
    rgba(89, 88, 88, 0.32) 0%,
    #9f9d9d 46.35%,
    rgba(97, 97, 97, 0) 100%
  );
}
.imageWrapper {
  width: 100%;
  max-width: 735px;
}
.imageWrapper img {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .list:nth-child(2),
  .list:nth-child(4) {
    padding-left: 30px;
  }
  .componentWrapper {
    flex-direction: column;
  }
  .imageWrapper {
    order: -1;
    width: 100%;
    max-width: 435px;
  }
  .list::before {
    display: none;
  }
  .list:nth-child(1)::after,
  .list:nth-child(3)::after {
    display: none;
  }
  .listWrapper {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0px;
    column-gap: 15px;
  }
  .list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }
  .number {
    font-size: 18px;
  }
  .title {
    font-size: 18px;
  }
}
