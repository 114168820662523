.loader {
  width: 100%;
  z-index: 100000000000000;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  background-color: #000;
}

.defaultLoaderImage {
  max-width: 150px;
  max-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 991px) {
  .loader {
    width: 100vw;
  }
}
