.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.wrapper::after {
  content: '';
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url('../../../../Assets/images/RoadMapHeroBg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 75vh;
  z-index: -1;
}

.heroSection {
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 252px;
}
.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 29px;
}
.title {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: capitalize;
}
.desc {
  color: #eee;
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  max-width: 813px;
}

.imageWrapper img {
  width: 100%;
}

@media screen and (max-width: 991px) {
  .heroSection {
    margin-top: 100px;
    gap: 100px;
  }
  .titleWrapper {
    gap: 20px;
  }
}

@media screen and (max-width: 425px) {
  .title {
    font-size: 34px;
  }
  .desc {
    color: #eee;
    text-align: center;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
  }
}
