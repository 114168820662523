.wrapper {
  margin-top: 140px;
  position: relative;
}

.backgroundImages {
  position: relative;
}
.backgroundImages::after {
  content: url('../../../../Assets/images/copyTradingZenLogo1.png');
  position: absolute;
  z-index: -1;
  top: 100%;
  left: 0;
}
.backgroundImages::before {
  content: url('../../../../Assets/images/copyTradingZenLogo2.png');
  position: absolute;
  z-index: -1;
  bottom: 100%;
  left: 50%;
}
.wrapper::before {
  display: inline;
  content: '';
  position: absolute;
  width: 992px;
  height: 371px;
  transform: rotate(24.629deg);
  flex-shrink: 0;
  border-radius: 992px;
  opacity: 0.95;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(18, 255, 170, 0.6) 31.77%,
    rgba(9, 9, 9, 0.08) 100%
  );
  filter: blur(191px);
  z-index: -100;
  animation: movingBackground 20s linear infinite;
}
.heroSection {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  padding-bottom: 170px;
  justify-content: center;
  align-items: center;
}
.textWrapper {
  max-width: 627px;
  display: flex;
  flex-direction: column;
}
.miniTitle {
  color: #049a5b;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-variant: small-caps;
  margin-bottom: 21px;
}
.title {
  color: #fff;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 142%;
  text-transform: capitalize;
  margin-bottom: 36px;
}
.content {
  max-width: 580px;
  color: #eee;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  margin-bottom: 56px;
}
.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageWrapper img {
  width: 100%;
  max-width: 600px;
}
.footerImage {
  width: 100%;
}
@media screen and (max-width: 1250px) {
  .heroSection::after {
    content: '';
  }
}
@media screen and (max-width: 1024px) {
  .wrapper {
    margin-top: 100px;
    position: relative;
  }
  .backgroundImages::after {
    content: '';
  }
  .backgroundImages::before {
    content: '';
  }
  .heroSection {
    grid-template-columns: 1fr;
    gap: 100px;
    padding-bottom: 10px;
  }
  .imageWrapper img {
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  .textWrapper {
    max-width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .heroSection::after {
    content: '';
  }
  .title {
    font-size: 22px;
  }
  .content {
    font-size: 16px;
  }
}

@media screen and (max-width: 425px) {
  .wrapper::before {
    display: none;
  }
}
/* Key Frame */

@keyframes movingBackground {
  0% {
    top: 0;
    right: 0;
    transform: rotate(0deg);
    opacity: 0;
    border-radius: 50%;
    background-color: rgba(86, 255, 193, 0.6);
  }
  25% {
    top: 50%;
    right: 50%;
    transform: rotate(90deg);
    opacity: 0.25;
    border-radius: 0;
    background-color: #00a86b;
  }
  50% {
    top: 100%;
    right: 100%;
    transform: rotate(180deg);
    opacity: 0.5;
    border-radius: 50%;
    background-color: #005e3b;
  }
  75% {
    top: 50%;
    right: 50%;
    transform: rotate(270deg);
    opacity: 0.25;
    border-radius: 0;
    background-color: #00311f;
  }
  100% {
    top: 0;
    right: 0;
    transform: rotate(360deg);
    opacity: 0;
    border-radius: 50%;
    background-color: rgba(18, 255, 170, 0.6);
  }
}
