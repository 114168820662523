/* --------------- Hero Section ----------- */
.heroSection {
  overflow: hidden;
  padding-block: 10rem 12rem;
  position: relative;
  background: url('../../Assets/images/Staking/stakingHeroLeftBottomSection.png'),
    url(' ../../Assets/images/ZenTokenView/zenTokenHeroCenterTopSection.png'),
    url(' ../../Assets/images/ZenTokenView/zenTokenHeroLeftBottomSection.png'),
    url(' ../../Assets/images/ZenTokenView/zenTokenHeroRightBgSection.png');
  background-repeat: no-repeat;
  background-position: bottom, center top, left 85%, right center;
  margin-bottom: 8rem;
}

/* --------------- Content ----------- */
.content {
  position: relative;
  background: url('../../Assets/images/Staking/stakingContentLeftTop.png'),
    url(' ../../Assets/images/Staking/stakingContentRightMiddle.png'),
    url(' ../../Assets/images/Staking/stakingContentLeftBottom.png');
  background-repeat: no-repeat;
  background-position: top left, right center, bottom left;
  margin-bottom: 8rem;
}
/* --------------- Content ----------- */

/* --------------- Hero Section ----------- */

.heroSection::before {
  display: inline;
  content: '';
  position: absolute;
  width: 992px;
  height: 371px;
  transform: rotate(24.629deg);
  flex-shrink: 0;
  border-radius: 992px;
  opacity: 0.95;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(18, 255, 170, 0.6) 31.77%,
    rgba(9, 9, 9, 0.08) 100%
  );
  filter: blur(191px);
  z-index: -100;

  animation: movingBackgroundOne 20s linear infinite;

  pointer-events: none;
}
.heroSection::after {
  display: inline;
  content: '';
  position: absolute;
  width: 992px;
  height: 371px;
  transform: rotate(24.629deg);
  flex-shrink: 0;
  border-radius: 992px;
  opacity: 0.25;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(18, 255, 170, 0.6) 31.77%,
    rgba(9, 9, 9, 0.08) 100%
  );
  filter: blur(191px);
  z-index: -100;

  animation: movingBackground 15s linear infinite;

  pointer-events: none;
}
@media screen and (max-width: 991px) {
  .heroSection {
    overflow: hidden;
    padding-block: 1rem;
    position: relative;
    background: url('../../Assets/images/Staking/stakingHeroLeftBottomSection.png'),
      url(' ../../Assets/images/ZenTokenView/zenTokenHeroCenterTopSection.png'),
      url(' ../../Assets/images/ZenTokenView/zenTokenHeroLeftBottomSection.png'),
      url(' ../../Assets/images/ZenTokenView/zenTokenHeroRightBgSection.png');
    background-repeat: no-repeat;
    background-position: bottom, center top, left 85%, right center;
    margin-bottom: 8rem;
  }
}
@media screen and (max-width: 425px) {
  .heroSection::before,
  .heroSection::after {
    display: none;
  }
}
/* --------------- Key Frames ----------- */

@keyframes movingBackground {
  0% {
    top: -10%;
    right: -10%;
    transform: rotate(0deg);
    opacity: 0;
    border-radius: 50%;
    background-color: rgba(86, 255, 193, 0.6);
  }
  25% {
    top: 0;
    right: 0;
    transform: rotate(90deg);
    opacity: 0.25;
    border-radius: 0;
    background-color: #00a86b;
  }
  50% {
    top: 50%;
    right: 50%;
    transform: rotate(180deg);
    opacity: 0.5;
    border-radius: 50%;
    background-color: #005e3b;
  }
  75% {
    top: 0;
    right: 0;
    transform: rotate(270deg);
    opacity: 0.25;
    border-radius: 0;
    background-color: #00311f;
  }
  100% {
    top: -10%;
    right: -10%;
    transform: rotate(360deg);
    opacity: 0;
    border-radius: 50%;
    background-color: rgba(18, 255, 170, 0.6);
  }
}

@keyframes movingBackgroundOne {
  0% {
    bottom: 0;
    right: 0%;
    opacity: 0.25;
  }
  50% {
    bottom: 100%;
    right: 100%;
    opacity: 0.25;
  }
  100% {
    bottom: 0;
    right: 0%;
    opacity: 0.25;
  }
}

/* --------------- Key Frames ----------- */
