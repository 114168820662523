.wrapper {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: relative;
}
.wrapper::after {
  display: inline;
  content: '';
  position: absolute;
  width: 992px;
  height: 371px;
  transform: rotate(24.629deg);
  flex-shrink: 0;
  border-radius: 992px;
  opacity: 0.25;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(18, 255, 170, 0.6) 31.77%,
    rgba(9, 9, 9, 0.08) 100%
  );
  filter: blur(191px);
  z-index: -100;
  bottom: 0;

  left: 0;

  /* Animation */
  /* animation: movingBackground 15s linear infinite; */

  /* Ignore pointer events */
  pointer-events: none;
}
.wrapper::before {
  display: inline;
  content: '';
  position: absolute;
  width: 992px;
  height: 371px;
  transform: rotate(24.629deg);
  flex-shrink: 0;
  border-radius: 992px;
  opacity: 0.25;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(18, 255, 170, 0.6) 31.77%,
    rgba(9, 9, 9, 0.08) 100%
  );
  filter: blur(191px);
  z-index: -100;

  /* Animation */
  /* animation: movingBackgroundTwo 30s linear infinite; */

  /* Ignore pointer events */
  pointer-events: none;
  bottom: 0;
  right: 0;
}
.heroSection {
  position: relative;
  margin-top: 138px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 47px;
  margin-bottom: 80px;
}
.title {
  color: #fff;
  text-align: center;
  font-size: 62px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-transform: capitalize;
}
.discreption {
  margin-block: 20px 32px;
  color: #eee;
  text-align: center;
  font-size: 20px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  max-width: 915px;
}
.videoButton {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: 0.3s;
}
.videoButton:hover {
  color: #00a86b;
}
.videoButton:hover .iconWrapper {
  transform: scale(1.1);
}
.iconWrapper {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s;
  font-size: 18px;
  color: #fff;
}

.iconWrapper:hover {
  transform: scale(1.1);
}
.imageWrapper img {
  vertical-align: bottom;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .heroSection {
    margin-top: 70px;
  }
  .title {
    font-size: 52px;
    line-height: 100%;
  }
  .discreption {
    font-size: 18px;
    line-height: 145%;
  }
}

@media screen and (max-width: 491px) {
  .heroSection {
    margin-top: 70px;
  }
  .buttonsWrapper {
    flex-direction: column;
    gap: 20px;
  }
  .title {
    font-size: 32px;

    line-height: 100%;
  }
  .discreption {
    font-size: 14px;
    line-height: 145%;
  }
}

/* Key Frames */
@keyframes movingBackground {
  0% {
    bottom: 0;
    right: 0;
    transform: rotate(0deg);
    opacity: 0;
    border-radius: 50%;
    background-color: rgba(86, 255, 193, 0.6);
  }
  25% {
    bottom: 50%;
    right: 50%;
    transform: rotate(45deg);
    opacity: 0.25;
    border-radius: 0;
    background-color: #00a86b;
  }
  50% {
    bottom: 100%;
    right: 100%;
    transform: rotate(90deg);
    opacity: 0.5;
    border-radius: 50%;
    background-color: #005e3b;
  }
  75% {
    bottom: 50%;
    right: 50%;
    transform: rotate(135deg);
    opacity: 0.25;
    border-radius: 0;
    background-color: #00311f;
  }
  100% {
    bottom: 0;
    right: 0;
    transform: rotate(180deg);
    opacity: 0;
    border-radius: 50%;
    background-color: rgba(18, 255, 170, 0.6);
  }
}

@keyframes movingBackgroundTwo {
  0% {
    top: 0;
    left: 0;
    transform: rotate(0deg);
    opacity: 0;
    border-radius: 50%;
    background-color: rgba(86, 255, 193, 0.6);
  }
  25% {
    top: 50%;
    left: 50%;
    transform: rotate(-90deg);
    opacity: 0.25;
    border-radius: 0;
    background-color: #00a86b;
  }
  50% {
    top: 100%;
    left: 100%;
    transform: rotate(-180deg);
    opacity: 0.5;
    border-radius: 50%;
    background-color: #005e3b;
  }
  75% {
    top: 50%;
    left: 50%;
    transform: rotate(-270deg);
    opacity: 0.25;
    border-radius: 0;
    background-color: #00311f;
  }
  100% {
    top: 0;
    left: 0;
    transform: rotate(-360deg);
    opacity: 0;
    border-radius: 50%;
    background-color: rgba(18, 255, 170, 0.6);
  }
}
