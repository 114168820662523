.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block: 100px;
  height: 100dvh;
  position: relative;
}
.wrapper::after {
  display: inline;
  content: '';
  position: absolute;
  width: 992px;
  height: 371px;
  transform: rotate(24.629deg);
  flex-shrink: 0;
  border-radius: 992px;
  opacity: 0.25;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(18, 255, 170, 0.6) 31.77%,
    rgba(9, 9, 9, 0.08) 100%
  );
  filter: blur(191px);
  z-index: -100;

  /* Animation */
  animation: movingBackground 15s linear infinite;

  /* Ignore pointer events */
  pointer-events: none;
}

.formContainer {
  display: flex;
  flex-direction: column;
  max-width: 897px;
  width: 100%;
  gap: 34px;
}
.rowWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 22px;
}
.title {
  color: #eee;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 142%;
  max-width: 426px;
  margin-bottom: 42px;
}
.singleInputField {
  width: 436px;
}
.singleTextField {
  width: 897px;
  margin-bottom: 34px;
}
.submitBtn {
  width: 377px;
}
.good {
  font-feature-settings: 'ss06' on;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%;
  color: #00a86b;
}
.bad {
  font-feature-settings: 'ss06' on;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%;
  color: #a80000;
}
@media screen and (max-width: 991px) {
  .submitBtn {
    width: 436px;
  }
  .singleTextField {
    width: 436px;
    margin-bottom: 34px;
  }
  .rowWrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 560px) {
  .wrapper::after {
    display: none;
  }
  .title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 22px;
  }
  .submitBtn,
  .singleTextField,
  .singleInputField {
    max-width: 436px;
    width: 100%;
  }

  .rowWrapper {
    flex-direction: column;
  }
}

/* @media screen and (max-width: 320px) {
  .submitBtn,
  .singleTextField,
  .singleInputField {
    max-width: 280px;
    width: 100%;
  }
} */
/* Key Frame */

@keyframes movingBackground {
  0% {
    top: 0;
    right: 0;
    transform: rotate(0deg);
    opacity: 0;
    border-radius: 50%;
    background-color: rgba(86, 255, 193, 0.6);
  }
  25% {
    top: 50%;
    right: 50%;
    transform: rotate(90deg);
    opacity: 0.25;
    border-radius: 0;
    background-color: #00a86b;
  }
  50% {
    top: 100%;
    right: 100%;
    transform: rotate(180deg);
    opacity: 0.5;
    border-radius: 50%;
    background-color: #005e3b;
  }
  75% {
    top: 50%;
    right: 50%;
    transform: rotate(270deg);
    opacity: 0.25;
    border-radius: 0;
    background-color: #00311f;
  }
  100% {
    top: 0;
    right: 0;
    transform: rotate(360deg);
    opacity: 0;
    border-radius: 50%;
    background-color: rgba(18, 255, 170, 0.6);
  }
}
