.test {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  width: 100%;
  background-color: red;
  z-index: 10000000000000;
  overflow: hidden;
}
