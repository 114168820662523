.rowWrapper {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 50px;
  grid-template-columns: 1fr 1fr;
}
.miniTitle {
  color: #049a5b;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-variant: small-caps;
  margin-bottom: 21px;
}

.title {
  color: #fff;
  /* heading */
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 142%; /* 51.12px */
  text-transform: capitalize;
  max-width: 628px;
  margin-bottom: 43px;
}

.content {
  color: #eee;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  max-width: 580px;
  margin-bottom: 56px;
}

.heroImage {
  width: 100%;
  height: 100%;
  max-width: 630px;
}
.textContainer {
  width: 100%;
  max-width: 628px;
}

@media screen and (max-width: 1024px) {
  .textContainer,
  .rowWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .miniTitle {
    color: #049a5b;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-variant: small-caps;
    margin-bottom: 21px;
  }

  .title {
    color: #fff;
    /* heading */
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 142%; /* 51.12px */
    text-transform: capitalize;
    max-width: 628px;
    margin-bottom: 43px;
  }

  .content {
    color: #eee;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    max-width: 580px;
    margin-bottom: 56px;
  }
}
