.heroSection {
  overflow: hidden;
  padding-block: 7rem 20rem;
  position: relative;
  background: url('../../Assets/images/FaitWalletView/FaitWalletHeroCenterImage.png'),
    url('../../Assets/images/FaitWalletView/FaitWalletHeroBottomImage.png'),
    url('../../Assets/images/FaitWalletView/FaitWalletHeroTopRightImage.png'),
    url('../../Assets/images/FaitWalletView/FaitWalletHeroTopRightStripImage.png'),
    url('../../Assets/images/FaitWalletView/FaitWalletHeroLeftBottomImage.png'),
    url('../../Assets/images/FaitWalletView/FaitWalletHeroLeftBottomStripImage.png');
  background-repeat: no-repeat;
  background-position: center center, bottom, top right, top right, left 74%,
    left bottom;
  background-size: 80%, 100%, 20%, contain, 20%, contain;
  margin-bottom: 0rem;
}

.sectionOne {
  padding-block: 6rem;
  position: relative;
  background: url('../../Assets/images/FaitWalletView/FaitWalletOneCenterImage.png'),
    url('../../Assets/images/FaitWalletView/FaitWalletOneCenterStripImage.png');
  background-repeat: no-repeat;
  background-position: center center, center center;
  background-size: 70%, 60%;
  /* margin-bottom: 8rem; */
}

.sectionThree {
  padding-block: 8rem;
  position: relative;
  background: url('../../Assets/images/FaitWalletView/FaitWalletThreeBGImage.png');
  background-repeat: no-repeat;
  background-position: right 150%;
  background-size: 80%;
  /* margin-bottom: 8rem; */
}

@media screen and (max-width: 992px) {
  .heroSection {
    padding-block: 5rem;
  }
}
