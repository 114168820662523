.content {
  position: relative;
}
.wrapperExperience {
  margin-bottom: 200px;
}
.expreience {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  align-items: center;
  justify-content: center;
}
.expreienceImage {
  width: 100%;
  height: 100%;
  max-width: 632px;
}
.wrapperImage {
  display: flex;
  justify-content: center;
  align-items: center;
}
.exclusiveImage {
  width: 100%;
  height: 100%;
  max-width: 431px;
}
.title {
  color: #fff;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.desc {
  margin-top: 24px;
  color: rgba(238, 238, 238, 0.8);
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
/* section 2 */
.wrapperUnluck {
  margin-bottom: 200px;
}
.unluck {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cardWrapper {
  margin-top: 87px;
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.mainTitle {
  color: #fff;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.cardTitle,
.cardRightTitle {
  color: #fff;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  z-index: 100;
}
.cardContent,
.cardRightContent {
  margin-top: 12px;
  color: #eee;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%;
}
.cardContent {
  max-width: 271px;
}
.cardRightContent {
  max-width: 408px;
}
.cardOne {
  position: relative;
  width: 352px;
  height: 447px;
  padding: 33px;
  border-radius: 10px;
  background: linear-gradient(177deg, #101010 0%, rgba(16, 16, 16, 0) 100%);
  transition: 0.5s;
}
.cardOne:hover {
  background-color: #23a7724d;
}
.cardOne::after {
  content: url('../../../../Assets/images/ZenTokenView/zenTokenContentRewards.png');
  position: absolute;
  bottom: 0;
  right: 0;
}
.cardRigh {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.cardTwo,
.cardThree {
  width: 670px;
  height: 213px;
  padding: 33px;
  border-radius: 10px;
  background: linear-gradient(177deg, #101010 0%, rgba(16, 16, 16, 0) 100%);
  position: relative;
  transition: 0.5s;
}
.cardTwo:hover,
.cardThree:hover {
  background-color: #23a7724d;
}
.cardTwo::after {
  content: url('../../../../Assets/images/ZenTokenView/zenTokenContentLock.png');
  position: absolute;
  bottom: 0;
  right: 0;
}
.cardThree::after {
  content: url('../../../../Assets/images/ZenTokenView/zenTokenContentChart.png');
  position: absolute;
  bottom: 0;
  right: 0;
}
/* section 4 */
.start {
  padding-block: 56px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.signinLink {
  margin-top: 22px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.signinLinkText,
.signinLinkIcon {
  color: #049a5b;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.titleCrypto {
  color: #fff;
  font-family: Raleway;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.leftWrapper p {
  color: rgba(238, 238, 238, 0.8);
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 574px;
}
@media screen and (max-width: 1050px) {
  .start {
    padding-block: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .rightWrapper,
  .leftWrapper p {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .cardOne {
    width: 670px;
    height: 213px;
  }
  .cardContent {
    max-width: 408px;
  }
  .cardWrapper {
    margin-top: 27px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .wrapperUnluck,
  .wrapperExperience {
    margin-bottom: 60px;
  }
  .expreience {
    grid-template-columns: 1fr;
    gap: 20px;
    text-align: center;
  }

  .title {
    font-size: 22px;
  }
  .desc {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .titleCrypto {
    font-size: 22px;
  }
  .signinLinkText,
  .signinLinkIcon {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .leftWrapper p {
    font-size: 16px;
  }
  .cardOne::after,
  .cardTwo::after,
  .cardThree::after {
    content: '';
  }
  .mainTitle {
    font-size: 26px;
    text-align: center;
  }
  .cardTitle,
  .cardRightTitle {
    font-size: 18px;
  }
  .cardContent,
  .cardRightContent {
    font-size: 14px;
  }
  .cardOne,
  .cardTwo,
  .cardThree {
    max-width: 670px;
    max-height: 213px;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 33px;
    border-radius: 10px;
    background: linear-gradient(177deg, #101010 0%, rgba(16, 16, 16, 0) 100%);
    position: relative;
  }
  .cardTwo {
    margin-bottom: 20px;
  }
}
