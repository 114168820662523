.card {
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(255, 255, 255, 0.05) 100%
    ),
    #000;
  position: relative;
  padding: 10px 30px;
  width: 358.695px;
  height: 168px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
}
.card:hover {
  transform: translateY(-10px);
}
.cardAngular {
  position: absolute;
  top: -72px;
  right: -62px;
}
.cardNumber {
  color: #fff;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 21px;
}

.cardDesc {
  color: #eee;
  leading-trim: both;
  text-edge: cap;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
  max-width: 186.841px;
}
.cardImage {
  width: 100px;
  height: 100px;
}
.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
@media screen and (max-width: 550px) {
  .card:hover {
    transform: unset;
  }
  .cardAngular {
    display: none;
  }
  .cardNumber {
    font-size: 16px;
    margin-bottom: 21px;
  }

  .cardDesc {
    font-size: 14px;
  }
  .cardImage {
    width: 100px;
    height: 100px;
  }
  .cardWrapper {
    flex-direction: column;
    justify-content: center;
  }
  .card {
    padding: 20px;
    max-width: 358.695px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .textWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
}
