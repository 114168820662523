.wrapper {
  position: relative;
}
.topWrapper,
.topWrapperHide {
  /* animation: showtop 0.5s ease; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 3%;
  bottom: 3%;
  background-color: #23a773;
  border: 2px solid #23a773;
  width: 60px;
  height: 60px;
  z-index: 1000;
  border-radius: 50%;
  color: white;
  transition: all 0.3s ease;
  cursor: pointer;
}
.topWrapperHide,
.topWrapperHide svg {
  bottom: 0;
  visibility: hidden;
  opacity: 0;
}
.icon {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topWrapper:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 2px solid rgba(255, 255, 255, 0.3);
}
@media screen and (max-width: 425px) {
  .topWrapper {
    width: 40px;
    height: 40px;
  }
  .icon {
    font-size: 14px;
  }
}

/* @keyframes showtop {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes hidetop {
  0% {
    opacity: 1;
    bottom: 3%;
  }
  100% {
    opacity: 0;
    bottom: 0%;
    display: none;
  }
} */
