/* --------------- Container Class ----------- */
.containerStyle {
  padding-inline: 40px;
}

.firstWrapper {
  padding-inline: 125px;
}

.secondWrapper {
  padding-inline: 234px;
}
/* --------------- Container Class ----------- */

/* ------------ Primary Button ------------- */
.buttonStyle {
  border-radius: 4px;
  border: 1.5px solid #23a773;
  background-color: #23a773;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 16px 30px;
  cursor: pointer;
  transition: 0.3s;
  width: fit-content;
  white-space: nowrap;
}
.buttonStyle > a {
  border-radius: 4px;
  border: 1.5px solid #23a773;
  background-color: #23a773;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 16px 30px;
  cursor: pointer;
  transition: 0.3s;
}

.buttonStyle:hover {
  opacity: 0.8;
}
.buttonStyle:hover > a {
  color: var(--orange-color);
}
.buttonStyle:active {
  scale: 1.05;
}

/* ----------- Colored Button ------------ */
.greenBtn {
  background: #339657;
  border-radius: 100px;
  text-transform: capitalize;
  border: 1px solid #339657;
  font-weight: 600;
}
.greenBtn > a {
  color: var(--white-color);
}
.greenBtn:hover {
  background-color: #fff;
  color: #339657;
  border: 1px solid #339657;
}
.greenBtn:hover > a {
  color: #339657;
}

/* ------- Disabled Button ------- */
.buttonStyleDisabled {
  background-color: var(--disabled-btn-color);
  border: unset;
}
.buttonStyleDisabled > a {
  cursor: not-allowed;
  background-color: var(--disabled-btn-color);
  color: var(--disabled-btn-text-color);
}
.buttonStyleDisabled:hover {
  background-color: var(--disabled-btn-color);
}
.buttonStyleDisabled:hover > a {
  background-color: var(--disabled-btn-color);
  color: var(--disabled-btn-text-color);
}
.buttonStyleDisabled:active {
  scale: 1;
}
/* ------------ inputField ------------- */
.inputFieldStyle,
.inputFieldErrorStyle,
.inputFieldSuccessStyle {
  color: #aaa;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 15px;
  flex-direction: row-reverse;
  align-items: center;
  gap: 5px;
  border: 1px solid #222;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.inputFieldErrorStyle {
  border-radius: 4px;
  border: 1px solid #ad0404;
  background-color: rgba(196, 110, 110, 0.12);
}
.inputFieldSuccessStyle {
  border-radius: 4px;
  border: 1px solid #04ad60;
  background-color: rgba(110, 196, 169, 0.12);
}
.inputFieldStyle > input,
.inputFieldErrorStyle > input,
.inputFieldSuccessStyle > input,
.inputFieldStyle > textarea,
.inputFieldErrorStyle > textarea,
.inputFieldSuccessStyle > textarea {
  padding: 5px;
  font-size: 18px;
  color: var(--contact-info-text);
  caret-color: var(--card-text-color);
  transition: all 0.5s ease;
  padding-right: 30px;
  font-weight: 500;
}
.inputFieldStyle > input::placeholder,
.inputFieldErrorStyle > input::placeholder,
.inputFieldSuccessStyle > input::placeholder,
.inputFieldStyle > textarea::placeholder,
.inputFieldErrorStyle > textarea::placeholder,
.inputFieldSuccessStyle > textarea::placeholder {
  color: var(--placeholder-color);
  font-weight: 500;
}
.inputFieldLabelStyle {
  display: block;
  color: var(--text-subtitle);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: start;
  white-space: break-spaces;
}
.inputFieldErrorMessageStyle {
  margin: 5px 0;
  font-size: 12px;
  text-align: start;
  color: var(--error-text);
}

/* ------------ Test Size ------------- */
.smallDesc {
  font-size: 14px;
}
@media screen and (max-width: 992px) {
  .firstWrapper {
    padding-inline: 75px;
  }

  .secondWrapper {
    padding-inline: 100px;
  }
}
@media screen and (max-width: 768px) {
  .containerStyle {
    padding-inline: 20px;
  }

  .firstWrapper {
    padding-inline: 40px;
  }

  .secondWrapper {
    padding-inline: 40px;
  }
  .smallDesc {
    font-size: 12px;
  }
}

@media screen and (max-width: 425px) {
  .inputFieldStyle,
  .inputFieldErrorStyle,
  .inputFieldSuccessStyle {
    border: 1px solid #ccc;
  }
}
