.wrapper {
  position: relative;
}
.cardWrapper {
  max-width: 392px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  position: relative;
}
.cardWrapper::before {
  content: '';
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #eeeeee3d;
}
.cardWrapper::after {
  content: '';
  position: absolute;
  top: 50px;
  left: 0;
  width: 0%;
  height: 1px;
  background-color: #049a5b;
  transition: width 0.5s ease;
}
.cardWrapper:hover::after {
  width: 100%; /* When hovering, set the width of the pseudo-element to 100% */
}
.number {
  color: #049a5b;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding-bottom: 23px;
  width: 100%;
  max-width: 392px;
  /* eeeeee3d */
}
.title {
  color: #fff;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-block: 23px;
}
.content {
  color: rgba(238, 238, 238, 0.8);
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}

.cardListWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 50px;
  column-gap: 70px;
}

.cardListWrapper {
  margin-top: 61px;
}

.mainTitle {
  color: #fff;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-items: start;
}
.subTitle {
  color: rgba(238, 238, 238, 0.8);
  /* body */
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}

.howItWorks {
  display: flex;
  flex-direction: column;
  text-align: start;
}
.wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-block: 200px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.wrapperFull {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-block: 200px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.imageFull {
  width: 80%;
  height: 80%;
  max-width: 843px;
  max-height: 843px;
  animation: bomb-animation 2s infinite;
}
.imageWrapper img {
  width: 100%;
  max-width: 440px;
}

@media screen and (max-width: 1200px) {
  .imageWrapper,
  .imageWrapper img {
    display: none;
  }
  .wrapper {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 1024px) {
  .wrapper {
    margin-block: 200px;
  }
  .mainTitle {
    font-size: 24px;
  }
  .subTitle {
    font-size: 14px;
  }
  .cardListWrapper {
    grid-template-columns: 1fr;
    column-gap: 30px;
  }
  .cardWrapper {
    align-items: center;
    text-align: center;
  }

  .number {
    font-size: 18px;
  }
  .title {
    font-size: 18px;
  }
  .content {
    font-size: 14px;
  }
  .howItWorks {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .howItWorks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 425px) {
  .cardListWrapper {
    max-width: 250px;
  }
}

@keyframes bomb-animation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.06);
  }
}
