.footerWrapper {
  font-family: 'Inter';
  font-style: normal;
  color: #aaaaaa;
  padding: 0 40px;
  position: relative;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}
.footer {
  padding-block: 51px;
}
.footerWrapper::before {
  display: inline;
  content: '';
  position: absolute;
  width: 992px;
  height: 371px;
  transform: rotate(24.629deg);
  flex-shrink: 0;
  border-radius: 992px;
  opacity: 0.95;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(18, 255, 170, 0.6) 31.77%,
    rgba(9, 9, 9, 0.08) 100%
  );
  filter: blur(191px);
  z-index: -100;

  /* Animation */
  animation: movingBackgroundOne 20s linear infinite;

  /* Ignore pointer events */
  pointer-events: none;
}

.footerWrapper::after {
  display: inline;
  content: '';
  position: absolute;
  width: 992px;
  height: 371px;
  transform: rotate(24.629deg);
  flex-shrink: 0;
  border-radius: 992px;
  opacity: 0.25;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(18, 255, 170, 0.6) 31.77%,
    rgba(9, 9, 9, 0.08) 100%
  );
  filter: blur(191px);
  z-index: -100;

  /* Animation */
  animation: movingBackground 15s linear infinite;

  /* Ignore pointer events */
  pointer-events: none;
}

.logoWrapper {
  display: flex;
  flex-direction: column;
  gap: 21px;
}
.logoImage {
  width: 117px;
  height: 37px;
}
.signinLink {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 3px;
}
.signinLinkIcon {
  font-size: 17px;
  color: #00d085;
}
.socialIcon {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  background-color: #aaa;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

.socialIcon:hover {
  background-color: #00d085;
  transform: scale(1.1);
}
.signinLinkText {
  color: #00d085;
  font-size: 14px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.footerTop {
  display: grid;
  grid-template-columns: 3fr 5fr;
  gap: 30px;
}

.linkTitle {
  color: #eee;
  text-align: center;
  font-size: 18px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  font-variant: small-caps;
  line-height: normal;
}
.moreWrapper a,
.productWrapper a {
  color: #aaa;
  text-align: center;
  font-size: 14px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: 0.3s;
}
.moreWrapper a:hover,
.productWrapper a:hover {
  color: #00d085;
}

.contactInfo,
.contactInfo a {
  color: rgba(238, 238, 238, 0.8);
  font-size: 16px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 450px;
}
.contactInfo a {
  border-bottom: 1px solid rgba(238, 238, 238, 0.8);
  transition: 0.3s;
}
.contactInfo a:hover {
  color: #00d085;
  border-bottom: 1px solid #00d085;
}
.links {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.moreWrapper,
.productWrapper {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 26px;
}
.contactWrapper {
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: start;
}

.social {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 11px;
  column-gap: 14px;
}
.information {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 61px;
  margin-bottom: 41px;
}
.informationText {
  color: rgba(238, 238, 238, 0.5);
  text-align: center;
  font-size: 13px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  max-width: 746px;
}
.devider {
  background-color: #282828;
  height: 1px;
  width: 100%;
  margin-block: 30px;
}
.footerBottomWrapper {
  position: relative;
}
.footerBottom {
  display: flex;
  justify-content: center;
  align-items: center;
}
.textFooter {
  color: rgba(238, 238, 238, 0.5);
  text-align: center;
  font-size: 14px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@media screen and (max-width: 991px) {
  .logoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 21px;
  }
  .footerTop {
    grid-template-columns: 1fr;
    gap: 50px;
  }
}
@media screen and (max-width: 768px) {
  .links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 30px;
  }
  .moreWrapper {
    text-align: center;
    align-items: center;
  }
  .contactWrapper {
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .footerWrapper::before,
  .footerWrapper::after {
    display: none;
  }
  .linksWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .links {
    grid-template-columns: 1fr;
  }
  .moreWrapper {
    text-align: center;
    align-items: center;
  }
}

/* Key Frame */

@keyframes movingBackground {
  0% {
    top: 0;
    right: 0;
    transform: rotate(0deg);
    opacity: 0;
    border-radius: 50%;
    background-color: rgba(86, 255, 193, 0.6);
  }
  25% {
    top: 50%;
    right: 50%;
    transform: rotate(90deg);
    opacity: 0.25;
    border-radius: 0;
    background-color: #00a86b;
  }
  50% {
    top: 100%;
    right: 100%;
    transform: rotate(180deg);
    opacity: 0.5;
    border-radius: 50%;
    background-color: #005e3b;
  }
  75% {
    top: 50%;
    right: 50%;
    transform: rotate(270deg);
    opacity: 0.25;
    border-radius: 0;
    background-color: #00311f;
  }
  100% {
    top: 0;
    right: 0;
    transform: rotate(360deg);
    opacity: 0;
    border-radius: 50%;
    background-color: rgba(18, 255, 170, 0.6);
  }
}

@keyframes movingBackgroundOne {
  0% {
    bottom: 0;
    right: 0%;
    opacity: 0.25;
  }
  50% {
    bottom: 100%;
    right: 100%;
    opacity: 0.25;
  }
  100% {
    bottom: 0;
    right: 0%;
    opacity: 0.25;
  }
}
